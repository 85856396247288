import Vue from 'vue'
import router from './router'
import Layout from './components/Layout.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './style/global.css'

Vue.config.productionTip = false
Vue.use(Antd)
new Vue({
  el: '#app',
  router,
  render: h => h(Layout)
})
