<template>
  <div class="layout">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from './Header.vue'
  import Footer from './Footer.vue'
  export default {
    name: 'Layout',
    components: { Header, Footer}
  }
</script>

<style lang="less" scoped>
.layout {
  padding-top: 64px;
}
</style>