<template>
  <div class="top-header">
    <div class="header">
      <div class="left-nav">
        <img src="http://anyouyun.cn/officialWeb/index/logo.png" alt="">
        <p>安友云智慧平台</p>
      </div>
      <div class="right-nav">
        <a-menu v-model="current" mode="horizontal" style="font-size: 16px;" @click="handleClick">
          <a-menu-item key="/index">首页</a-menu-item>
          <a-sub-menu popupClassName="popupClassName">
            <span slot="title" class="submenu-title-wrapper">解决方案</span>
            <a-menu-item key="/enterprise-solution">企业端解决方案</a-menu-item>
            <a-menu-item key="/project-solution">项目端解决方案</a-menu-item>
            <a-menu-item key="/construction-solution">安全施工解决方案</a-menu-item>
            <a-menu-item key="/dangerou-solution">危大工程解决方案</a-menu-item>
          </a-sub-menu>
          <a-menu-item key="/product">产品中心</a-menu-item>
          <a-menu-item key="/cases">经典案例</a-menu-item>
          <a-menu-item key="/qypt">区域平台</a-menu-item>
          <a-menu-item key="/about">关于我们</a-menu-item>
          <a-menu-item key="/call">加入我们</a-menu-item>
        </a-menu>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    data(){
      return {
        current: ['index'],
      }
    },
    methods: {
      handleClick(e) {
        this.$router.push(e.key)
      }
    },
    watch: {
      $route: {
        handler(newVal, oldVal) {
          window.scrollTo(0, 0)
          this.current = [newVal.path == '/cases-detail' ||  newVal.path == '/municipal-detail' ? '/cases' : newVal.path]
        },
        deep: true,
        immediate: true,
      },
    },
  }
</script>

<style lang="less" scope>
.top-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  background-color: #fff;
  .header {
    height: 64px;
    display: flex;
    align-items: center;
    .left-nav {
      display: flex;
      align-items: center;
      padding: 0 150px 0 350px;
      img {
        width: 100px;
        height: 64px;
        margin-right: 10px;
        cursor: pointer;
      }
      p {
        margin: 0;
        padding: 0 15px;
        border-left: 1px solid #dfdfdf;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
      }
    }
  }
}
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-item, .ant-menu-submenu-title {
  text-align: center;
}
.popupClassName {
  .ant-menu-vertical.ant-menu-sub,
  .ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-vertical-right.ant-menu-sub {
    min-width: 100px;
  }
}
</style>