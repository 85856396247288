import Vue from 'vue'
import Router from 'vue-router'
// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: '/index'
        },
        {
            path: '/index',
            name: '首页',
            component: () => import('@/pages/index/index')
        },
        // 解决方案
        {
            path: '/enterprise-solution',
            name: '企业端解决方案',
            component: () => import('@/pages/solution/enterprise-solution')
        },
        {
            path: '/project-solution',
            name: '项目端解决方案',
            component: () => import('@/pages/solution/project-solution')
        },
        {
            path: '/construction-solution',
            name: '安全施工解决方案',
            component: () => import('@/pages/solution/construction-solution')
        },
        {
            path: '/dangerou-solution',
            name: '危大工程解决方案',
            component: () => import('@/pages/solution/dangerou-solution')
        },
        {
            path: '/product',
            name: '产品中心',
            component: () => import('@/pages/product/index')
        },
        {
            path: '/cases',
            name: '经典案例',
            component: () => import('@/pages/cases/index')
        },
        {
            path: '/cases-detail',
            name: '房建项目',
            component: () => import('@/pages/cases/cases-detail')
        },
        {
            path: '/cases-detail-page1',
            name: '房建项目1',
            component: () => import('@/pages/cases/cases-detail-page1')
        },
        {
            path: '/municipal-detail',
            name: '市政项目',
            component: () => import('@/pages/cases/municipal-detail')
        },
        {
            path: '/qypt',
            name: '关于我们',
            component: () => import('@/pages/qypt/index')
        },
        {
            path: '/about',
            name: '关于我们',
            component: () => import('@/pages/about/index')
        },
        {
            path: '/call',
            name: '加入我们',
            component: () => import('@/pages/call/index')
        },
    ]
})

Vue.use(Router)