<template>
  <div class="to-footer">
    <div class="footer">
      <div class="container">
        <div class="container-left">
          <div>
            <h3>解决方案</h3>
            <p key="2-4" @click="open('enterprise-solution')">企业端解决方案</p>
            <p key="2-5" @click="open('project-solution')">项目端解决方案</p>
            <p key="2-6" @click="open('construction-solution')">安全施工解决方案</p>
            <p key="2-7" @click="open('dangerou-solution')">危大工程解决方案</p>
          </div>
          <div>
            <h3>产品中心</h3>
            <p key="2-2" @click="open('product')">人员管理</p>
            <p key="2-1" @click="open('product')">危大工程</p>
            <p key="2-3" @click="open('product')">社区管理</p>
            <p key="2-4" @click="open('product')">物料管理</p>
            <p key="2-5" @click="open('product')">智慧工地</p>
          </div>
          <div>
            <h3>关于我们</h3>
            <p key="6-1" @click="open('about')">公司简介</p>
            <p key="6-2" @click="open('about')">资质证书</p>
            <p key="6-3" @click="open('about')">合作伙伴</p>
            <p key="6-4" @click="open('call')">加入我们</p>
          </div>
        </div>
        <div class="container-right">
          <div class="lxfs">
            <div>产品技术支持</div>
            <p>13485138082</p>
            <div>产品联系电话</div>
            <p>0512-69577920</p>
          </div>
          <div class="qcode">
            <img src="http://anyouyun.cn/officialWeb/index/qcode.png" width="150">
            <div>关注我们</div>
          </div>
        </div>
      </div>
      <div class="information">
        <div>Copyright ©2019-2027 江苏安快智能科技有限公司 版权所有</div>
        <div @click="url">备案号: 苏ICP备20042640号-10</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data(){
      return {
      }
    },
    methods: {
      open(router){
        this.$router.push(router)
      },
      url(){
        window.open('https://beian.miit.gov.cn/', '_blank')
      }
    }
  }
</script>

<style lang="less" scoped>
.to-footer {
  min-width: 1200px;
  height: 350px;
  background-color: #161b25;
  color: #FFF;
  padding: 40px 0;
  .footer {
    width: 1200px;
    margin: 0 auto;
    .container {
      height: 260px;
      display: flex;
      .container-left {
        width: 700px;
        display: flex;
        justify-content: space-around;
        border-right: 1px solid #4d4d4d;
        padding: 0 20px;
      }
      .container-right {
        width: 500px;
        padding: 0 20px;
        display: flex;
        .lxfs {
          width: 240px;
          margin: 20px 20px 0;
          text-align: center;
          div {
            color: #b2b2b2;
            font-size: 18px;
          }
          p {
            font-size: 18px;
            color: #FFF;
          }
        }
        .qcode {
          width: 180px;
          text-align: center;
          div {
            color: #b2b2b2;
            margin-top: 20px;
          }
        }
      }
    }
    .information {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      div {
        margin: 0 40px;
        cursor: pointer;
      }
    }
  }
  h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }
  p {
    color: #b2b2b2;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }
  p:hover {
    color: #ffb575;
  }
}
</style>